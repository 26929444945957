import { useCallback, useContext } from 'react'
import { ParamsContext } from '~/contexts/ParamsContext'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { generateHash } from '~/utils/generateHash'
import { generateUuid } from '~/utils/generateUUID'
import { facebookMetaGraph } from '~/services/api'

interface StudentData {
  basicInfoData?: {
    email?: string
    fullName?: string
    phone?: string
    fn?: string
    ln?: string
    birthDate?: string
    city?: string
    state?: string
    zipcode?: string
    country?: string
  }
  paymentMethod?: string
  paymentStatus?: boolean
}

interface CustomData {
  value: number
  currency: string
  contents: any[]
  content_type: string
}

const generateCustomData = (totalValue: number, contents: any[], extraFields = {}) => ({
  value: totalValue,
  currency: 'BRL',
  contents: contents,
  content_type: 'product',
  ...extraFields,
})

const createEventData = (
  eventName: string,
  studentData: StudentData,
  customData: CustomData,
  clientIpAddress: string,
  clientUserAgent: string,
  baseEventID: string,
  fbp: string,
) => {
  const userData = {
    ...(studentData?.basicInfoData?.email && { em: generateHash(studentData?.basicInfoData?.email) }),
    ...(studentData?.basicInfoData?.fullName && { fn: generateHash(studentData?.basicInfoData?.fullName) }),
    ...(studentData?.basicInfoData?.phone && { ph: generateHash(studentData?.basicInfoData?.phone) }),
    ...(studentData?.basicInfoData?.fn && { ln: generateHash(studentData?.basicInfoData?.fn) }),
    ...(studentData?.basicInfoData?.ln && { ln: generateHash(studentData?.basicInfoData?.ln) }),
    ...(studentData?.basicInfoData?.birthDate && { db: generateHash(studentData?.basicInfoData?.birthDate) }),
    ...(studentData?.basicInfoData?.city && { ct: generateHash(studentData?.basicInfoData?.city) }),
    ...(studentData?.basicInfoData?.state && { st: generateHash(studentData?.basicInfoData?.state) }),
    ...(studentData?.basicInfoData?.zipcode && { zp: generateHash(studentData?.basicInfoData?.zipcode) }),
    ...(studentData?.basicInfoData?.country && { country: generateHash(studentData?.basicInfoData?.country) }),
    client_ip_address: clientIpAddress,
    client_user_agent: clientUserAgent,
    external_id: baseEventID,
    fbp: fbp,
  }

  const eventSourceUrl = typeof window !== 'undefined' ? window.location.href : ''

  return {
    event_name: eventName,
    event_time: Math.floor(Date.now() / 1000),
    action_source: 'website',
    user_data: userData,
    event_source_url: eventSourceUrl,
    custom_data: customData,
  }
}

const initializedPixels = new Set()

export const useAnalytics = () => {
  const { clientIpAddress, clientUserAgent } = useContext(CheckoutContext)
  const { cartData, partnerData, partnerSlug } = useContext(ParamsContext)

  const sendEvent = useCallback(
    async (eventName: string, studentData: StudentData = {}) => {
      const totalValue = parseFloat(cartData?.totalPriceToPay) || 0
      const contents =
        cartData?.courses?.map((course) => ({
          id: course.CourseId || '',
          quantity: 1,
          price: course.price || 0,
        })) || []

      let customData: CustomData = generateCustomData(totalValue, contents)
      if (eventName === 'Purchase') {
        customData = generateCustomData(totalValue, contents, {
          payment_method: studentData?.paymentMethod || 'unknown',
          payment_status: studentData?.paymentStatus || 'pending',
        })
      }
      const baseEventID = `${cartData?.PartnerId}-${partnerSlug}`

      const fbp =
        document.cookie
          .split('; ')
          .find((row) => row.startsWith('_fbp='))
          ?.split('=')[1] || null

      const eventData = {
        data: [createEventData(eventName, studentData, customData, clientIpAddress, clientUserAgent, baseEventID, fbp)],
      }

      const eventIdMap = new Map()

      if (typeof window !== 'undefined' && window.fbq) {
        const trackingDataArray = Array.isArray(partnerData?.trackingData) ? partnerData.trackingData : []

        for (const tracking of trackingDataArray) {
          const pixelId = tracking?.credentials?.pixelId
          const enabledEvents = tracking?.enabledEvents || {}
          const selectedPayment = studentData?.paymentMethod
          const accessToken = !!tracking?.credentials?.accessToken

          if (!initializedPixels.has(pixelId)) {
            window.fbq.disablePushState = true
            window.fbq('init', pixelId)
            window.fbq('set', 'autoConfig', false, pixelId)
            initializedPixels.add(pixelId)
          }

          if (!eventIdMap.has(eventName)) {
            eventIdMap.set(eventName, `${baseEventID}-${generateUuid()}`)
          }
          const eventID = eventIdMap.get(eventName)

          if (eventName === 'Purchase') {
            if (selectedPayment === 'CreditCard' && enabledEvents[selectedPayment] === true) {
              window.fbq('trackSingle', pixelId, 'Purchase', eventData, { eventID })
            } else if (accessToken && enabledEvents[selectedPayment] === true) {
              window.fbq('trackSingleCustom', pixelId, 'PaymentGenerated', eventData, { eventID })
            } else if (selectedPayment !== 'CreditCard' && !accessToken && enabledEvents[selectedPayment] === true) {
              window.fbq('trackSingle', pixelId, 'Purchase', eventData, { eventID })
            }
          } else if (eventName !== 'Purchase') {
            window.fbq('trackSingle', pixelId, eventName, eventData, { eventID })
          }
        }
      }

      if (Array.isArray(partnerData?.trackingData) && partnerData.trackingData.length > 0) {
        await Promise.all(
          partnerData?.trackingData
            ?.filter((tracking: any) => tracking.credentials?.accessToken)
            .map(async (tracking: any) => {
              const pixelId = tracking?.credentials?.pixelId
              const enabledEvents = tracking?.enabledEvents || {}
              const selectedPayment = studentData?.paymentMethod
              const accessToken = tracking?.credentials?.accessToken
              const testEventCode = tracking.credentials?.testEventCode

              if (!accessToken) {
                return
              }

              const eventID = eventIdMap.get(eventName)

              if (eventName === 'Purchase') {
                if (enabledEvents[selectedPayment] === true) {
                  await facebookMetaGraph(
                    { pixelId, accessToken },
                    {
                      ...eventData,
                      data: eventData.data.map((item) => ({
                        ...item,
                        event_name: selectedPayment === 'CreditCard' ? 'Purchase' : 'PaymentGenerated',
                        event_id: eventID,
                      })),
                      test_event_code: testEventCode,
                    },
                  )
                }
              } else if (eventName !== 'Purchase') {
                await facebookMetaGraph(
                  { pixelId, accessToken },
                  {
                    ...eventData,
                    data: eventData.data.map((item) => ({
                      ...item,
                      event_id: eventID,
                    })),
                    test_event_code: testEventCode,
                  },
                )
              }
            }),
        )
      }
    },
    [cartData, clientIpAddress, clientUserAgent, partnerData, partnerSlug],
  )

  return { sendEvent }
}
